<template>
  <div class="grid grid-cols-12 mt-5 space-x-2">
    <div class="col-span-2 font-medium text-right text-14px text-oCharcoal">
      {{ title }}
    </div>

    <div class="col-span-7" style="background: #f2f2f2; height: 20px">
      <progress-bar-x
        :value="value"
        :animated="true"
        :height="20"
        :rounded="0"
        :color="color"
      />
    </div>

    <div class="col-span-3 font-medium text-14px text-oCharcoal">
      {{ count }}
    </div>
  </div>
</template>

<script>
import ProgressBarX from '@/components/analytics/charts/ProgressBarX'

export default {
  name: 'HealthBarBlockItem',
  components: {
    ProgressBarX,
  },
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    value: {
      type: Number,
    },
    count: {
      type: Number,
    },
    color: {
      type: String,
      default: 'teal',
    },
  },
}
</script>

<style lang="scss" scoped></style>
