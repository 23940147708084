<template>
  <div>
    <!-- <div>{{ getPercentage }}%</div> -->
    <div class="loading-bar" :style="getStyle">
      <!-- <div class="percentage" :style="getPercentageStyle"></div> -->
      <div
        class="percentage"
        :class="getPercentageClass"
        @dragover="onClickDrag"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBarX',
  props: {
    color: {
      type: String,
      default: 'purple',
    },
    animated: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number,
      default: 10,
    },
    radius: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 100,
    },
  },
  methods: {
    onClickDrag(e) {
      console.log('drag = ', e)
    },
  },
  created() {
    var intval = setInterval(() => {
      if (this.percentage < this.value) this.percentage += 0.1
      else clearInterval(intval)
    }, 0.1)
  },
  data() {
    return {
      percentage: 0,
    }
  },
  computed: {
    getStyle() {
      return {
        width: this.percentage + '%',
        height: this.height + 'px',
        borderRadius: this.radius + 'px',
      }
    },
    getPercentageClass() {
      let colors = ['green', 'teal', 'purple', 'red', 'yellow']
      let color = colors.includes(this.color) ? this.color : 'purple'
      // console.log('progressBarX color = ', color)
      return `x-${color}`
    },
    getPercentageStyle() {
      return {
        animation: this.animated ? 'animate-stripes .1s linear' : 'none',
      }
    },
    getPercentage() {
      return this.percentage.toFixed()
    },
  },
}
</script>

<style lang="scss" scoped>
.loading-bar {
  position: relative;
  width: 100%;
  height: 10px;
  border-radius: 15px;
  overflow: hidden;
  // border-bottom: 1px solid #ddd;

  .percentage {
    position: absolute;
    // top: 0;
    left: 0;
    right: 0;
    display: block;
    height: 100%;
    margin: 0;
    //border-radius: 15px;
    // border-bottom: 1px solid #ededed;
    top: 50%;
    transform: translateY(-50%);
    background-color: #166aff;
    // background-image: linear-gradient(
    //   135deg,
    //   rgba($color: #fff, $alpha: 0.15) 25%,
    //   transparent 25%,
    //   transparent 50%,
    //   rgba($color: #fff, $alpha: 0.15) 50%,
    //   rgba($color: #fff, $alpha: 0.15) 75%,
    //   transparent 75%,
    //   transparent
    // );
    animation: animate-stripes 0.1s linear;
    &.x-purple {
      background-color: #166aff;
    }
    &.x-green {
      background-color: #09aa35;
    }
    &.x-red {
      background-color: #d90a20;
    }
    &.x-teal {
      background-color: #00c1f3;
    }
    &.x-yellow {
      background-color: #ffed00;
    }
  }
}

@keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 0;
  }
}

.nostripes > span > span,
.nostripes > span:after {
  animation: none;
  background-image: none;
}
</style>
